<template>
  <div class="mx-3 mx-md-6">
    <v-row align="center">
      <v-col cols="6">
        <div class="mt-6 headline">List of Foodcourts</div>
        <span class="text-caption grey--text"
          >*Please click the row to access the foodcourt</span
        >
      </v-col>
      <v-col cols="6" class="mt-5">
        <v-text-field
          v-model="search"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-col>
    </v-row>
    <v-data-table
      :loading="loading"
      :headers="headers"
      :items="foodcourts"
      :search="search"
      class="elevation-1 my-6"
      style="background-color: #151d21;cursor: pointer"
       @click:row="onClick($event)"
    >
    </v-data-table>
  </div>
</template>

<script>
import store from '../../store'
import { eventBus } from '../../main'
export default {
  data() {
    return {
      headers: [
        {
          text: "Foodcourt",
          align: "start",
          sortable: true,
          value: "foodcourt",
        },
        { text: "Owner", value: "owner", sortable: false, filterable: false },
        { text: "Subscription Package", value: "package", sortable: true },
        {
          text: "Subscription Expiry Date",
          value: "expire",
          sortable: true,
          align: "end",
          filterable: false,
        },
        // { text: 'Actions', value: 'actions', sortable: false },
      ],
      foodcourts: [],

      loading: true,
      search: "",
    }
  },
  created () {
    // console.log('foodcourt list creating')
    // console.log(this.$date)
    store.dispatch('getFoodcourt')
    .then(res => {
      console.log('foodcourt list', res.data.data.foodCourts)
      this.getData(res.data.data.foodCourts)
      this.loading = false
    }).catch(err => {
      console.log(err)
      this.loading = false
      eventBus.$emit('snackbar', {
        snackbarColor: "#FF5252",
        snackbarText: "Something Went Wrong!",
      })
    })
  },
  methods: {
    onClick(item) {
      // console.log(item)
      eventBus.$emit('single-foodcourt', item.id)
    },

    // for Data Table
    getData(data) {
      // console.log('before',data)
      data.forEach((item) => {
        let date = ''
        let packagename = ''
        if (item.subscription !== null) {
          date = this.$date(item.subscription.finished_at)
          packagename = item.subscription.packages[0].name
        }
        // console.log(date)

        const foodcourt = {
          foodcourt: item.name,
          owner: item.user.name,
          package: packagename,
          expire: date,
          id: item.id,
        };
        this.foodcourts.push(foodcourt)
      })
    }
  }
}
</script>

<style>

</style>
